import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

const Sidebar = () => {
  const { allWpCategory } = useStaticQuery(graphql`
    query SidebarBlogQuery {
      allWpCategory(
        sort: { fields: name, order: ASC }
        filter: { count: { gte: 1 } }
      ) {
        nodes {
          uri
          name
        }
      }
    }
  `);

  return (
    <div className="c-sidebar-box rounded-3">
      <h3 className="c-sidebar-title rounded-3 shadow h6">TOP PRODUCTS</h3>
      <ul className="h-clear-list h-bullet-list mt-0 mb-5">
        <li>
          <Link className="c-sidebar-link" to="/patient-portal-software/">
            Bridge Portal
          </Link>
        </li>
        <li>
          <Link className="c-sidebar-link" to="/patient-payments/">
            Bridge Pay
          </Link>
        </li>
        <li>
          <Link className="c-sidebar-link" to="/patient-scheduling-software/">
            Bridge Scheduling
          </Link>
        </li>
        <li>
          <Link className="c-sidebar-link" to="/telehealth-solution/">
            Bridge Telehealth
          </Link>
        </li>
        <li>
          <Link className="c-sidebar-link" to="/patient-engagement-mobile-app/">
            Bridge Mobile
          </Link>
        </li>
        <li>
          <Link className="c-sidebar-link" to="/patient-intake-solution/">
            Bridge Intake
          </Link>
        </li>
        <li>
          <Link className="c-sidebar-link" to="/task-management/">
            Bridge Tasking
          </Link>
        </li>
        <li>
          <Link className="c-sidebar-link" to="/integration-services/">
            Bridge Interface
          </Link>
        </li>
        <li>
          <Link className="c-sidebar-link" to="/patient-messaging-solution/">
            Bridge Communication
          </Link>
        </li>
      </ul>
      <h3 className="c-sidebar-title rounded-3 shadow h6">CATEGORIES</h3>
      <ul className="h-clear-list h-bullet-list mt-0">
        {allWpCategory.nodes.map(({ uri, name }) => {
          return (
            <li key={uri}>
              <Link className="c-sidebar-link" to={uri}>
                {name}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Sidebar;
